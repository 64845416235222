import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { getJobDescriptionById } from '../../http';


const Employee = () => {
  const { user } = useSelector(state => state.authSlice);
  const [isPersonalOpen, setPersonalOpen] = useState(false);
  const [isContactOpen, setContactOpen] = useState(false);
  const [isEmergencyOpen, setEmergencyOpen] = useState(false);
  const [isJobDescription,setJobDescription] = useState(false);
  const [userJob,setUserJob] = useState({});

  console.log(user.id);
  useEffect(async ()=>{

    const response = await getJobDescriptionById(user?.id);
    console.log(response.data.data);
    if(response.data.success)
    {
      console.log(response.data);
      setUserJob(response.data.data);
    }
  
  },[user.id]);

  return (
    <div className="">
      <section className="section">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4>Welcome {user?.name}</h4>
            <img
              className="img-fluid img-thumbnail rounded-circle"
              src={user.image}
              alt="Profile"
              style={{ width: '80px', height: '80px', objectFit: 'cover' }}
            />
          </div>
        </div>

        {/* Personal Information Card */}
        <div className="card mt-3">
          <div
            className="card-header text-white d-flex justify-content-between"
            onClick={() => setPersonalOpen(!isPersonalOpen)}
            style={{ cursor: 'pointer',backgroundColor:"#6777EF" }}
          >
            <h5 className="mb-0">Personal Information</h5>
            <span>{isPersonalOpen ? '-' : '+'}</span>
          </div>
          {isPersonalOpen && (
            <div className="card-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th className="bg-light">Name</th>
                    <td>{user.name}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Username</th>
                    <td>{user.username}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Email</th>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Gender</th>
                    <td>{user.gender}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Date Of Birth</th>
                    <td>{moment(user.dob).format("MMMM D, YYYY")}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Marital Status</th>
                    <td>{user.maritalStatus}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Contact Information Card */}
        <div className="card mt-3">
          <div
            className="card-header text-white d-flex justify-content-between"
            onClick={() => setContactOpen(!isContactOpen)}
            style={{ cursor: 'pointer',backgroundColor:"#6777EF" }}
          >
            <h5 className="mb-0">Contact Information</h5>
            <span>{isContactOpen ? '-' : '+'}</span>
          </div>
          {isContactOpen && (
            <div className="card-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th className="bg-light">Mobile</th>
                    <td>+{user.countryCode} {user.mobile}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Address</th>
                    <td>{user.address}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">State</th>
                    <td>{user.state}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Postal Code</th>
                    <td>{user.postalCode}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Country</th>
                    <td>{user.country ? user.country : 'N/A'}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">City</th>
                    <td>{user.city ? user.city : 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Emergency Contact Information Card */}
        <div className="card mt-3">
          <div
            className="card-header text-white d-flex justify-content-between"
            onClick={() => setEmergencyOpen(!isEmergencyOpen)}
            style={{ cursor: 'pointer',backgroundColor:"#6777EF" }}
          >
            <h5 className="mb-0">Emergency Contact Information</h5>
            <span>{isEmergencyOpen ? '-' : '+'}</span>
          </div>
          {isEmergencyOpen && (
            <div className="card-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th className="bg-light">Emergency Contact Name</th>
                    <td>{user.emergencyContactName}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Emergency Contact Number</th>
                    <td>{user.emergencyContactNumber}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Emergency Contact Relationship</th>
                    <td>{user.emergencyContactRelationship}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

          {/* Job Description Card */}

     {userJob ?      
          <div className="card mt-3">
          <div
            className="card-header text-white d-flex justify-content-between"
            onClick={() => setJobDescription(!isJobDescription)}
            style={{ cursor: 'pointer',backgroundColor:"#6777EF" }}
          >
            <h5 className="mb-0">Job Description</h5>
            <span>{isJobDescription ? '-' : '+'}</span>
          </div>
          {isJobDescription && (
            <div className="card-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th className="bg-light">Job Title</th>
                    <td>{userJob?.jobTitle}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Job Description</th>
                    <td>{userJob?.jobDescription}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Job Location</th>
                    <td>{userJob?.jobLocation}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">Team</th>
                    <td>{userJob?.department}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">employment Status</th>
                    <td>{userJob?.employmentStatus}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">employment Type</th>
                    <td>{userJob?.employmentType}</td>
                  </tr>
                  <tr>
                    <th className="bg-light">StartDate</th>
                    <td>{moment(userJob?.StartDate).format('MMMM Do YYYY')}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          )}
        </div>
      : <></>}

      </section>
    </div>
  );
};

export default Employee;
