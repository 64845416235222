import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { getTeam, updateTeam } from "../../http";
import { useHistory } from "react-router-dom";

const EditTeam = () => {
    const [imagePreview, setImagePreview] = useState('/assets/icons/team.png');
    const navigate = useHistory();
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image: '',
        status: ''
    });
    const [initialFormData, setInitialFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false); // To manage loading state
    const { id } = useParams();

    // Fetch team data on component mount
    useEffect(() => {
        (async () => {
            const res = await getTeam(id);
            if (res.data.success) {
                setFormData(res.data.data);
                setInitialFormData(res.data.data); // Save the initial data for comparison
                setImagePreview(res.data.data.image);
            }
        })();
    }, [id]);

    // Check for changes in form data
    useEffect(() => {
        const hasChanges = JSON.stringify(formData) !== JSON.stringify(initialFormData);
        setIsButtonEnabled(hasChanges);
    }, [formData, initialFormData]);

    const inputEvent = (e) => {
        const { name, value } = e.target;
        setFormData((old) => ({ ...old, [name]: value }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { name, description } = formData;
        if (!name || !description) {
            return toast.error('All Fields Required');
        }

        setIsUpdating(true); // Set loading state
        const fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd.append(key, formData[key]);
        });

        try {
            const res = await updateTeam(id, fd);
            if (res.data && res.data.success) {
                toast.success(res.data.message);
                setInitialFormData(formData); // Update initial data after successful submission
                setIsButtonEnabled(false); // Disable button after save
                navigate.push('/teams');
            } else {
                toast.error(res.data?.message || "Update failed");
            }
        } catch (error) {
            toast.error("An error occurred while updating the team. Please try again.");
        } finally {
            setIsUpdating(false); // Reset loading state
        }
    };

    const captureImage = (e) => {
        const file = e.target.files[0];
        setFormData((old) => ({ ...old, image: file }));

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => setImagePreview(reader.result);
    };

    const handleBack = () => {
        if (isButtonEnabled) {
            if (window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
                navigate.push(`/team/${id}`);
            }
        } else {
            navigate.push(`/team/${id}`);
        }
    };

    return (
        <>
            <div className="main-content">
                <section className="section">
                    <HeaderSection title="Edit Team" />
                    <div className="card">
                        <div className="card-body pr-5 pl-5 m-1">
                            <form className="row" onSubmit={onSubmit}>
                                <div className="form-group col-md-12 text-center">
                                    <div className="input-group justify-content-center">
                                        <input
                                            type="file"
                                            id="image"
                                            name="image"
                                            className="form-control d-none cursor-pointer"
                                            onChange={captureImage}
                                            accept="image/*"
                                        />
                                        <label htmlFor="image" className="image-upload-container">
                                            <img className="rounded cursor-pointer" src={imagePreview} width="120" alt="Team Preview" />
                                            <div className="image-upload-overlay">Click to Upload</div>
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Enter Team Name</label>
                                    <input
                                        onChange={inputEvent}
                                        value={formData.name || ''}
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Team Status</label>
                                    <select
                                        name="status"
                                        onChange={inputEvent}
                                        value={formData.status || ''}
                                        className="form-control select2"
                                    >
                                        <option>Active</option>
                                        <option>Banned</option>
                                        <option>Expired</option>
                                        <option>Deleted</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Enter Team Description</label>
                                    <input
                                        onChange={inputEvent}
                                        value={formData.description || ''}
                                        type="text"
                                        id="description"
                                        name="description"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group text-center col-md-12">
                                        <button
                                            className="btn btn-lg mr-3"
                                            type="submit"
                                            style={{
                                                width: '30vh',
                                                backgroundColor: isButtonEnabled && !isUpdating ? '#28a745' : '#d9534f', // Green when enabled, red when disabled
                                                cursor: isButtonEnabled && !isUpdating ? 'pointer' : 'not-allowed', // Pointer when enabled, not-allowed when disabled
                                                color: '#fff', // Text color
                                                border: 'none',
                                            }}
                                            disabled={!isButtonEnabled || isUpdating} // Disable button if no changes or updating
                                        >
                                            {isUpdating ? (
                                                <span>
                                                    <i className="fas fa-spinner fa-spin"></i> Updating...
                                                </span>
                                            ) : (
                                                "Update Team"
                                            )}
                                        </button>
                                        <button
                                            className="btn btn-primary btn-lg"
                                            type="button"
                                            style={{ width: '30vh' }}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </button>
                                    </div>

                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default EditTeam;
