import React, { useEffect, useState } from 'react';
import { markEmployeeAttendance, viewEmployeeAttendance } from '../../http';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../Loading';

const Attendance = () => {
  const { user } = useSelector(state => state.authSlice);
  const [isAttendanceMarked, setIsAttendanceMarked] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [attendance, setAttendance] = useState([]);
  const [resetData,setResetData] = useState([]);
  const [alreadyMarked,setAlreadyMarked] = useState(false);
  const [todayDate,setTodayDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const years = [2020, 2021, 2022, 2023, 2024,2025,2026,2027,2028];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthDays = {
    "January": 31, "February": 28, "March": 31, "April": 30,
    "May": 31, "June": 30, "July": 31, "August": 31,
    "September": 30, "October": 31, "November": 30, "December": 31,
  };
  
  const numOfDays = monthDays[selectedMonth];
  const days = Array.from({ length: numOfDays }, (_, index) => index + 1);

  

  // useEffect(() => {
  //   // console.log(todayDate);
  //   // console.log(todayDate);
  //   if (todayDate) {
  //     // const data = storedData;
  //     const dt = `${todayDate.date}/${todayDate.month}/${todayDate.year}`;
  //     console.log(dt);
  //     if (dt === new Date().toLocaleDateString()) {
  //       console.log("hello");
  //       setIsAttendanceMarked(true);
  //     }
  //   }
  // }, [user.id]);

  useEffect(() => {
    const fetchInitialAttendance = async () => {
      const dt = new Date();
      const obj = {
        employeeID: user.id,
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      };
      try {
        const res = await viewEmployeeAttendance(obj);
        const { data } = res.data;
        setResetData(data);
        setAttendance(data);
        
        const currentDate = data[data.length-1];
        console.log(currentDate);
        if (currentDate) {
          // Format `currentDate` to match the format of `toLocaleDateString`
          const formattedDate = `${currentDate.date}/${currentDate.month}/${currentDate.year}`;
          console.log("Formatted Date from currentDate:", formattedDate);
      
          // Get today's date in the same format
          const today = new Date();
          const todayFormatted = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
          console.log("Today's Formatted Date:", todayFormatted);
      
          // Compare both dates
          if (formattedDate === todayFormatted) {
              console.log("Attendance already marked for today.");
              setIsAttendanceMarked(true);
          } else {
              console.log("No attendance marked for today.");
              setIsAttendanceMarked(false);
          }
      } else {
          console.log("currentDate is not defined or is null.");
      }
      
        setTodayDate(attendance[attendance.length-1]);
      } catch (error) {
        toast.error("Failed to fetch attendance data");
      }
    };
    fetchInitialAttendance();
  }, [user.id]);

  // console.log(attendance[attendance.length-1]);
  const markAttendance = async () => {
    try {
      const res = await markEmployeeAttendance({ employeeID: user.id });
  
      // Check if response has expected data
      if (res && res.data) {
        const { success, message, newAttendance } = res.data;
  
        if (success) {
          toast.success(message);
          localStorage.setItem(user.id, JSON.stringify(newAttendance));
          setIsAttendanceMarked(true);
        } else {
          toast.error(message || "Unable to mark attendance");
        }
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      // Capture specific error message if available
      const errorMessage =
        error.response?.data?.message || error.message || "Failed to mark attendance";
      toast.error(errorMessage);
  
      console.error("Error marking attendance:", error); // Logs the complete error details
    }
  };
  
console.log(isAttendanceMarked);
  const searchAttendance = async () => {
    if (!selectedYear && !selectedMonth && !selectedDay) return;
    const obj = { employeeID: user.id };
    if (selectedYear) obj.year = selectedYear;
    if (selectedMonth) obj.month = months.findIndex(month => month === selectedMonth) + 1;
    if (selectedDay) obj.date = selectedDay;
    
    try {
      const res = await viewEmployeeAttendance(obj);
      setAttendance(res.data.data);
      setCurrentPage(1);
    } catch (error) {
      toast.error("Error fetching attendance data");
    }
  };


  const resetHandler = ()=>{

    setSelectedYear('');
    setSelectedMonth('');
    setSelectedDay('');
    setAttendance(resetData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = attendance.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(attendance.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  console.log(isAttendanceMarked);

  return (
    <>
      {attendance ? (
        <div className="main-content">
          <section className="section">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h4>Attendance</h4>
                <button
                  className={`btn btn-lg ${isAttendanceMarked ? "btn-secondary" : "btn-primary"} btn-icon-split`}
                  onClick={markAttendance}
                  disabled={isAttendanceMarked}
                >
                  {isAttendanceMarked ? "Attendance Marked" : "Mark Attendance"}
                </button>
              </div>
            </div>
            
            <div className="d-flex justify-content-center w-100">
              <div className="col">
                <select
                  className='form-control select2'
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="">Year</option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>        
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  className='form-control select2'
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">Month</option>
                  {months.map(month => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  className='form-control select2'
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                >
                  <option value="">Day</option>
                  {days.map(day => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
              <button onClick={searchAttendance} className="btn btn-primary  mr-5 col">Search</button>
              <button onClick={resetHandler} className="btn btn-danger col">Reset</button>
            </div>
          </section>
          
          <div className="table-responsive mt-5">
            <table className="table table-striped table-md center-text">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Day</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((att, idx) => (
                  <tr key={idx}>
                    <td>{indexOfFirstItem + idx + 1}</td>
                    <td>{`${att.date}/${att.month}/${att.year}`}</td>
                    <td>{att.day}</td>
                    <td>{att.present ? "Present" : "Absent"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <nav className="pagination-controls d-flex justify-content-end">
            <ul className="pagination">
              <li className="page-item">
                <button 
                  onClick={handlePrev} 
                  className="page-link" 
                  disabled={currentPage === 1}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages).keys()].map(number => (
                <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                  <button onClick={() => paginate(number + 1)} className="page-link">
                    {number + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button 
                  onClick={handleNext} 
                  className="page-link" 
                  disabled={currentPage === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Attendance;
