import { useEffect, useState } from "react";
import { useParams, NavLink,useHistory } from "react-router-dom";
import { getUser, getJobDescriptionByForAdmin,updateUserJobDescription } from "../../http";
import UpdateJobModal from "../team/team/modal/UpdateJobDescriptionModal";
import { toast, ToastContainer } from 'react-toastify';
import moment from "moment";

const Employee = () => {
  const navigate = useHistory();
  const [user, setUser] = useState({
    name: '',
    email: '',
    mobile: '',
    image: '',
    address: '',
    status: '',
    type:''
  });

  const { id } = useParams();
  const [userJob, setUserJob] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [jobData, setJobData] = useState({
    jobTitle: '',
    jobDescription: '',
    jobLocation: '',
    team: '',
    employmentStatus: '',
    employmentType: '',
    startDate: '',
  });

  // Fetch user data
  const getUserData = async (id) => {
    const res = await getUser(id);
    if (res.data.success) setUser(res.data.data);
  };

  // Fetch job details
  const getUserJobDetails = async (id) => {
    const response = await getJobDescriptionByForAdmin(id);
    if (response.data.success) {
      setUserJob(response.data.data);
      setJobData(response.data.data)
    }
  };

  useEffect(() => {
    getUserData(id);
    getUserJobDetails(id);
  }, [id,setUserJob]);

  useEffect(()=>{},[

  ])

  // Sync jobData with userJob
  useEffect(() => {
    if (userJob) {
        console.log(userJob);
      setJobData({
        jobTitle: userJob?.jobTitle,
        jobDescription: userJob?.jobDescription,
        jobLocation: userJob?.jobLocation,
        team: userJob?.team,
        employmentStatus: userJob?.employmentStatus,
        employmentType: userJob?.employmentType,
        _id:userJob?._id,
        employeeID:userJob?.employeeID,
        startDate: moment(userJob?.startDate).format("YYYY-MM-DD"),
      });
    }
  }, [userJob]);

  // Handle updates from modal
  const handleUpdate = async (updatedData) => {
    setJobData(updatedData); // Update jobData state
    try {
      const response = await updateUserJobDescription(id, updatedData);
      setUserJob(response.data.data);
  
      // Show success toast notification
      toast.success('Job updated successfully!');
    } catch (err) {
      console.log(err);
  
      // Show error toast notification
      toast.error('Failed to update job!');
    }
    console.log('Updated Job Data:', updatedData);
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <div className="section-header d-flex justify-content-between">
            <h1>Employee</h1>
            <div className="d-flex justify-between">
              <button className="btn btn-danger mr-4 pr-4 pl-4" onClick={(e)=>{e.preventDefault(); navigate.push("/employees")}}>
                    Back
              </button>
              <NavLink to={`/edituser/${id}`} className="btn btn-primary mr-4">
                Edit {user.type}
              </NavLink>
              <button
                onClick={() => setModalVisible(true)}
                className="btn btn-primary"
              >
                Edit Job Description
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-body row">
              <div className="col-md-3">
                <img
                  className="img-fluid img-thumbnail"
                  src={user.image}
                  alt=""
                />
              </div>
              <div className="col-md-9">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{user.name}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>+{user?.countryCode} {user.mobile}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{user.country}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{user.address}</td>
                    </tr>
                {
                  userJob?.jobTitle ?
                      <>
                        <tr>
                          <th>Job Title</th>
                          <td>{userJob?.jobTitle}</td>
                        </tr>
                        <tr>
                          <th>Job Description</th>
                          <td>{userJob?.jobDescription}</td>
                        </tr>
                        <tr>
                          <th>Employment Status</th>
                          <td>{userJob?.employmentStatus}</td>
                        </tr>
                        <tr>
                          <th>Employment Type</th>
                          <td>{userJob?.employmentType}</td>
                        </tr>
                    </> :<></>
               }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <UpdateJobModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onUpdate={handleUpdate}
          initialData={jobData}
        />
      </div>
    </>
  );
};

export default Employee;