import { useEffect, useState,useMemo } from "react";
import { useParams,useHistory } from "react-router";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { updateUser, getUser } from "../../http";
import Modal from '../../components/modal/Modal';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import countryCodes from "country-codes-list"
import moment from "moment";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../../App.css"

const EditUser = () => {
    const countries = useMemo(() => {
        return Object.entries(countryCodes.customList('countryNameEn', '{countryNameEn} ({countryCallingCode})')).map(
            ([key, value]) => {
                const codeMatch = value.match(/\((\d+)\)/); // Extracts the country calling code
                return {
                    name: key, // Country name (e.g., "United States")
                    displayName: value, // Full display name (e.g., "United States (1)")
                    code: codeMatch ? codeMatch[1] : '' // Extracts the calling code (e.g., "1")
                };
            }
        );
    }, []);
    
    const [imagePreview, setImagePreview] = useState('/assets/icons/user.png');
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [updateFormData, setUpdatedFormData] = useState({});
    const [userType, setUserType] = useState('User');
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [initialFormData, setInitialFormData] = useState({});
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const { id } = useParams();
    const navigate = useHistory();

    useEffect(() => {
        (async () => {
            const res = await getUser(id);
            console.log(res.data);
            if (res.data.success) {
                setUserType(res.data.data.type);
                setFormData(res.data.data);
                setInitialFormData(res.data.data);
                setImagePreview(res.data.data.image);
            }
        })();
    }, [id]);

        // Check for changes in form data
        useEffect(() => {
            const normalizeMobile = (number) => number ? number.toString().replace(/\D/g, '') : '';
        
            const normalizedFormData = { 
                ...formData, 
                mobile: normalizeMobile(formData.mobile),
                emergencyContactNumber: normalizeMobile(formData.emergencyContactNumber)
                
            };
            
            const normalizedInitialData = { 
                ...initialFormData, 
                mobile: normalizeMobile(initialFormData.mobile),
                emergencyContactNumber: normalizeMobile(initialFormData.emergencyContactNumber)
            };
        
            const hasChanges = JSON.stringify(normalizedFormData) !== JSON.stringify(normalizedInitialData);
            setIsButtonEnabled(hasChanges);
        }, [formData, initialFormData]);
        
        
    const inputEvent = (e) => {
        const { name, value } = e.target;
        console.log(e.target);
        setFormData((old) => ({
            ...old,
            [name]: value
        }));
        setUpdatedFormData((old) => ({
            ...old,
            [name]: value
        }));

    };

    // Navigate to Employee


    const backnavigateToEmployee = ()=>{
        navigate.push(`/employee/${id}`);
    };

    const backNavigateToAdmin = ()=>{
        navigate.push(`/admin/${id}`);
    };
    const captureImage = (e) => {
        const file = e.target.files[0];
        setFormData((old) => ({
            ...old,
            profile: file
        }));
        setUpdatedFormData((old) => ({
            ...old,
            profile: file
        }));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
    };

    // const onSubmit = async (e) => {
    //     e.preventDefault();

    //     // If the form type is set and the modal is not shown, show the modal
    //     if (updateFormData.type && !showModal) return setShowModal(true);
    
    //     console.log("ForData",formData);
    //     // Check if countryCode is already present in formData
    //     if (!formData.countryCode || selectedCountryCode) {
    //         formData.countryCode = selectedCountryCode; // Add selectedCountryCode if not present
    //     }
    //     const fd = new FormData();
    
    //     // Append form data from state to FormData object
    //     for (let key in formData) {
    //         fd.append(key, formData[key]);
    //     }
    
    
    
    //     // Update the user data
    //     try {
    //         const res = await updateUser(id, fd);
    //         if (res.data.success) {
    //             toast.success(res.data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error updating user:', error);
    //     }
    // };

    const onSubmit = async (e) => {
        e.preventDefault();
    
        // If the form type is set and the modal is not shown, show the modal
        if (updateFormData.type && !showModal) return setShowModal(true);
    
        console.log("FormData:", formData);
    
        // Validation rules
        const alphabeticRegex = /^[A-Za-z\s]+$/; // For names, nationalities, etc.
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // For email validation
        const numericRegex = /^[0-9]+$/; // For numeric-only values
        const mobileRegex = /^[0-9]{10}$/; // For valid 10-digit mobile numbers
    
        // Destructure the necessary fields for validation
        const {
            name,
            email,
            mobile,
            password,
            dob,
            emergencyContactName,
            emergencyContactRelationship,
            emergencyContactNumber,
            postalCode,
            profile,
            nationality,
            country,
            state,
            city,
        } = formData;
    
        // Validation checks
        if (!name || !alphabeticRegex.test(name.trim())) {
            return toast.error("Name must contain only alphabets and spaces.");
        }
        if (!email || !emailRegex.test(email.trim())) {
            return toast.error("Enter a valid email address.");
        }
        if (!mobile || !mobileRegex.test(mobile) || !numericRegex.test(mobile)) {
            return toast.error("Mobile number must be a valid 10-digit number.");
        }
      
        // if (!dob || new Date(dob) >= new Date()) {
        //     return toast.error("Date of birth must be in the past.");
        // }
        if (!emergencyContactName || !alphabeticRegex.test(emergencyContactName)) {
            return toast.error("Emergency contact name must contain only alphabets and spaces.");
        }
        if (!emergencyContactRelationship || !alphabeticRegex.test(emergencyContactRelationship)) {
            return toast.error("Emergency contact relationship must contain only alphabets and spaces.");
        }
        if (!emergencyContactNumber || !mobileRegex.test(emergencyContactNumber)) {
            return toast.error("Emergency contact number must be a valid 10-digit number.");
        }
        if (!postalCode || !/^[A-Za-z0-9]{2,10}$/.test(postalCode)) {
            return toast.error("Postal code must be alphanumeric characters.");
        }
        // if (!profile) {
        //     return toast.error("Please upload a profile image.");
        // }
        if (!nationality || !alphabeticRegex.test(nationality.trim())) {
            return toast.error("Nationality must contain only alphabets and spaces.");
        }
        if (!state || !alphabeticRegex.test(state.trim())) {
            return toast.error("State must contain only alphabets and spaces.");
        }
        if (!city || !alphabeticRegex.test(city.trim())) {
            return toast.error("City must contain only alphabets and spaces.");
        }
        if (!country || !state || !city) {
            return toast.error("Country, State, and City are required.");
        }
    
        // Check if countryCode is already present in formData
        if (!formData.countryCode || selectedCountryCode) {
            formData.countryCode = selectedCountryCode; // Add selectedCountryCode if not present
        }
        
        setIsUpdating(true);
        const fd = new FormData();
    
        // Append form data from state to FormData object
        for (let key in formData) {
            fd.append(key, formData[key]);
        }
    
        // Update the user data
        try {
            const res = await updateUser(id, fd);
            if (res.data.success) {
                toast.success(res.data.message);
                setInitialFormData(formData); // Update initial data after successful submission
                setIsButtonEnabled(false); // Disable button after save
            }
        } catch (error) {
            console.error("Error updating user:", error);
            toast.error("Failed to update user. Please try again.");
        }
        finally{
            setIsUpdating(false);
        }
    };
    

    const handleCountryChange = (e) => {
        const selectedCountry = countries.find(country => country.name === e.target.value);
        setFormData((old) => ({
            ...old,
            country: selectedCountry.name
        }));
        console.log(selectedCountry.code)
        setSelectedCountryCode(selectedCountry.code);
    };

    const modalAction = () => setShowModal(!showModal);

    return (
        <>
            {showModal && 
                <Modal close={modalAction} title="Update User" width='35%'>
                    <div className="row" style={{ margin: '20px' }}>
                        <div className="col col-md-4">
                            <div className="input-group justify-content-center text-center">
                                <img className='rounded' src={imagePreview} width='120' alt="" /> 
                            </div>
                        </div>
                        <div className="col col-md-8">
                            <table className='table table-md'>
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>{formData.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{formData.email}</td>
                                    </tr>
                                    <tr>
                                        <th>User Type</th>
                                        <td>{formData.type}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Enter Your Password</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="fas fa-lock"></i>
                                </div>
                            </div>
                            <input onChange={inputEvent} value={formData.adminPassword} type="password" placeholder={`Enter Your Password To Change ${formData.name}'s Type`} id='adminPassword' name='adminPassword' className="form-control" />
                        </div>
                    </div>
                    <div className="justify-content-center text-center mb-3">
                        <button className='btn btn-primary btn-lg' type='submit' form='updateUserForm' style={{ width: '30vh' }}>Add {formData.type}</button>
                    </div>
                </Modal>
            }
            <div className="main-content">
                <section className="section">
                    <HeaderSection title={`Edit ${userType}`} />
                    {/* <NavLink to="/">Home</NavLink> */}
                    <div className="card">
                        <div className="card-body pr-5 pl-5 m-1">
                            <form className='row' onSubmit={onSubmit} id='updateUserForm'>
                                <div className="form-group col-md-12 text-center">
                                <div className="input-group justify-content-center">
                                <input
                                    type="file"
                                    id="profile"
                                    name="profile"
                                    className="form-control d-none"
                                    onChange={captureImage}
                                    accept="image/*"
                                />
                                <label
                                    htmlFor="profile"
                                    className="profile-label"
                                >
                                    <img
                                        className="rounded"
                                        src={imagePreview}
                                        width="120"
                                        alt={formData.name}
                                    />
                                     <i className="fas fa-upload upload-icon"></i>
                                </label>
                            </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Enter Name</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.name} type="text" id='name' name='name' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Enter Email</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-envelope"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.email} type="email" id='email' name='email' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Date Of Birth</label>
                                    <input onChange={inputEvent} value={ moment(formData.dob).format('YYYY-MM-DD')} type="date" id='dob' name='dob' className="form-control" min="1900-01-01"
     max={new Date().toISOString().split("T")[0]}/>
                                </div>
                                {/* <div className="form-group col-md-4">
                                    <label>Enter Username</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-user-circle"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.username} type="text" id='username' name='username' className="form-control" />
                                    </div> */}
                                {/* </div> */}
                                <div className="form-group col-md-2">
                                    <label>Gender</label>
                                    <select name='gender' onChange={inputEvent} value={formData.gender} className="form-control">
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-3">
                                    <label>Enter Mobile Number</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-phone"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.mobile} type="text" id='mobile' name='mobile' className="form-control" />
                                    </div>
                               
                                </div>

                                <div className="form-group col-md-3">
                                    <label>Enter Password</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.password} type="password" id='password' name='password' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>User Type</label>
                                    <select name='type' onChange={inputEvent} value={formData.type} className="form-control select2">
                                        <option>Employee</option>
                                        <option>Leader</option>
                                        <option>Admin</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>User Status</label>
                                    <select name='status' onChange={inputEvent} value={formData.status} className="form-control select2">
                                        <option>Active</option>
                                        <option>Banned</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>Marital Status</label>
                                    <select name='marital' onChange={inputEvent} value={formData.maritalStatus} className="form-control">
                                        <option>Single</option>
                                        <option>Married</option>
                                    </select>
                                </div>
                                {/* Additional Fields */}
                                <div className="form-group col-md-3">
                                    <label>Emergency Contact Name</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactName} type="text" id='contactName' name='emergencyContactName' className="form-control"/>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Emergency Contact Relationship</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactRelationship} type="text" id='contactRelationship' name='emergencyContactRelationship' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Number</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactNumber} type="number" id='contactNumber' name='emergencyContactNumber' className="form-control"/>
                                </div>
                                {/* <div className="form-group col-md-4">
                                    <label>SIN or PAN</label>
                                    <input onChange={inputEvent} value={formData.sin} type="text" id='sin' name='sin' className="form-control"/>
                                </div> */}
                                <div className="form-group col-md-2">
                                    <label>Nationality</label>
                                    <input onChange={inputEvent} value={formData.nationality} type="text" id='nationality' name='nationality' className="form-control"/>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Country</label>
                                    <select
                                        id="country"
                                        name="country"
                                        className="form-control"
                                        value={formData.country}
                                        onChange={handleCountryChange}
                                    >
                                        {countries.map((country) => (
                                            <option key={country.name} value={country.name}>
                                                {country.displayName}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <input onChange={inputEvent} value={formData.country} type="text" id='country' name='country' className="form-control"/> */}
                                </div>
                                <div className="form-group col-md-3">
                                    <label>State</label>
                                    <input onChange={inputEvent} value={formData.state} type="text" id='state' name='state' className="form-control"/>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>City</label>
                                    <input onChange={inputEvent} value={formData.city} type="text" id='city' name='city' className="form-control"/>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>Postal Code</label>
                                    <input onChange={inputEvent} value={formData.postalCode} type="text" id='postalCode' name='postalCode' className="form-control"/>
                                </div>
                                <div className="form-group col-md-12 ">
                                    <label>Enter Address</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.address} type="text" id='address' name='address' className="form-control" />
                                    </div>
                                </div>
                                
                                <div className="form-group text-center col-md-12">
                                <button
                                        className="btn btn-lg mr-3"
                                        type="submit"
                                        style={{
                                            width: '30vh',
                                            backgroundColor: isButtonEnabled && !isUpdating ? '#28a745' : '#d9534f', // Green when enabled, red when disabled
                                            cursor: isButtonEnabled && !isUpdating ? 'pointer' : 'not-allowed', // Pointer when enabled, not-allowed when disabled
                                            color: '#fff', // Text color
                                            border: 'none',
                                        }}
                                        disabled={!isButtonEnabled || isUpdating} // Disable button if no changes or updating
                                    >
                                        {isUpdating ? (
                                            <span>
                                                <i className="fas fa-spinner fa-spin"></i> Updating...
                                            </span>
                                        ) : (
                                            <span>
                                            Update {userType}
                                            </span>
                                        )}
                                    </button>
                                    <button className='btn btn-primary btn-lg' onClick={userType == "Admin" ? backNavigateToAdmin : backnavigateToEmployee} style={{ width: '30vh'}} >Back</button>
                                    {/* <button className='btn btn-primary btn-lg' type='submit' style={{ width: '30vh'}}  disabled={!isButtonEnabled}>Update {userType}</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default EditUser;
