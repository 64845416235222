import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderSection from "../../components/HeaderSection";
import RowTeam from "../../components/rows/team-row";
import { getTeams } from "../../http";
import { setTeam } from "../../store/team-slice";
import { setTeamMembers } from "../../store/user-slice";

const Teams = () => {
    const dispatch = useDispatch();
    dispatch(setTeam(null));
    dispatch(setTeamMembers(null));

    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of teams to display per page

    useEffect(() => {
        (async () => {
            console.log("Teams Page");
            const res = await getTeams();
            console.log("Teams Page",res);
            if (res?.data?.success) {
                setTeams(res.data.data);
                setLoading(false);
            }
        })();
    }, []);

    // Calculate total pages
    const totalPages = Math.ceil(teams.length / itemsPerPage);

    // Get current page's teams
    const currentTeams = teams.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="main-content">
                <section className="section">
                    <HeaderSection title="Teams" />
                    <div className="card">
                        <div className="card-header">
                            <h4>All Teams</h4>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-striped table-md center-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Leader</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading &&
                                            currentTeams.map((data, index) => {
                                                return (
                                                    <RowTeam
                                                        key={index}
                                                        index={(currentPage - 1) * itemsPerPage + index + 1}
                                                        data={data}
                                                    />
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            {/* Bootstrap Pagination Controls */}
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center m-3">
                                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                            key={i}
                                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(i + 1)}
                                            >
                                                {i + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Teams;
