import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import { toast } from "react-toastify";
import { getTeams } from '../../../../http';

const UpdateJobModal = ({ visible, onClose, onUpdate, initialData }) => {
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [team, setTeam] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [allTeamMember, setAllTeamMember] = useState([]);
  const [defaultSelectedTeamName, defaultSetSelectedTeamName] = useState('');
    console.log(initialData);
  const fetchTeams = async () => {
    try {
      const response = await getTeams();
      if (response.data.success) {
        setAllTeamMember(response.data.data);
      } else {
        toast.error("Unable to fetch Team Member Records");
      }
    } catch (err) {
      toast.error("Error fetching Team Members");
    }
  };

  useEffect(() => {
    if (initialData) {
      setJobTitle(initialData.jobTitle || '');
      setJobDescription(initialData.jobDescription || '');
      setJobLocation(initialData.jobLocation || '');
      setTeam(initialData.team || '');
      setEmploymentStatus(initialData.employmentStatus || '');
      setEmploymentType(initialData.employmentType || '');
      setStartDate(initialData.startDate ? moment(initialData.startDate).format('YYYY-MM-DD') : '');
    }
    console.log("initalDateTeam",initialData.team);
    fetchTeams();
  }, [initialData]);

  useEffect(() => {
    if (allTeamMember.length > 0 && initialData?.team) {
      const teamName = allTeamMember.find((team) => team.id === initialData.team)?.name;
      // console.log(teams);
      defaultSetSelectedTeamName(teamName || 'N/A');
    }
  }, [allTeamMember, initialData]);

  const handleUpdate = () => {
    console.log(team);
    const updatedData = {
      jobTitle,
      jobDescription,
      jobLocation,
      team: team,
      employmentStatus,
      employmentType,
      startDate,
    };
    onUpdate(updatedData);
    onClose();
  };

 
  return (
    <Modal show={visible} onHide={onClose} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>Update Job Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formJobTitle" className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job title"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formJobDescription" className="mb-3">
            <Form.Label>Job Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job description"
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formJobLocation" className="mb-3">
            <Form.Label>Job Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job location"
              value={jobLocation}
              onChange={(e) => setJobLocation(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formTeam" className="mb-3">
            <Form.Label>Select Team</Form.Label>
            <Form.Control
              as="select"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
              defaultValue={defaultSelectedTeamName}
              required
            >
              <option value="" disabled>Select a Team</option>
              {allTeamMember.length === 0 ? (
                <option value="" disabled>N/A</option>
              ) : (
                allTeamMember.map((team) => (
                  <option key={team?._id} value={team?._id}>
                    {team?.name}
                  </option>
                ))
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formEmploymentStatus" className="mb-3">
            <Form.Label>Employment Status</Form.Label>
            <Form.Control
              as="select"
              value={employmentStatus}
              onChange={(e) => setEmploymentStatus(e.target.value)}
              required
            >
              <option value="" disabled>Select Employment Status</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contract">Contract</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formEmploymentType" className="mb-3">
            <Form.Label>Employment Type</Form.Label>
            <Form.Control
              as="select"
              value={employmentType}
              onChange={(e) => setEmploymentType(e.target.value)}
              required
            >
              <option value="" disabled>Select Employment Type</option>
              <option value="Permanent">Permanent</option>
              <option value="Temporary">Temporary</option>
              <option value="Internship">Internship</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formStartDate" className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateJobModal;
