import React, { useEffect, useState } from 'react';
import { viewLeaveApplicationsByEmployee } from '../../http';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';

const LeaveApplications = () => {
  const { user } = useSelector(state => state.authSlice);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]); // New state for filtered applications
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 10;
  const history = useHistory();

  // Fetch all data on initial load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const obj = { "applicantID": user?.id };
        const res = await viewLeaveApplicationsByEmployee(obj);
        const data = Array.isArray(res.data?.data) ? res.data.data : [];
        setApplications(data);
        setFilteredApplications(data); // Initialize filtered applications
      } catch (error) {
        console.error("Error fetching leave applications", error);
        setApplications([]);
        setFilteredApplications([]);
      } finally {
        setLoading(false);
      }
    };
    if (user?.id) {
      fetchData();
    }
  }, [user?.id]);

  // Filter applications when filter values change
  // useEffect(() => {
  //   const filtered = applications.filter((application) => {
  //     const matchesType = type ? application.type === type : true;
  //     const matchesStatus = status ? application.adminResponse === status : true;
  //     const matchesAppliedDate = appliedDate ? application.appliedDate === appliedDate : true;
  //     return matchesType && matchesStatus && matchesAppliedDate;
  //   });
  //   setFilteredApplications(filtered);
  // }, [type, status, appliedDate, applications]);

  const filterApplicationHandler = ()=>{
    const filtered = applications.filter((application) => {
      const matchesType = type ? application.type === type : true;
      const matchesStatus = status ? application.adminResponse === status : true;
      const matchesAppliedDate = appliedDate ? application.appliedDate === appliedDate : true;
      return matchesType && matchesStatus && matchesAppliedDate;
    });
    setFilteredApplications(filtered);
  }

  const resetHandler = ()=>{
    setType('');
    setStatus('');
    setAppliedDate('');
    setFilteredApplications(applications);
  };

  const indexOfLastApplication = currentPage * rowsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - rowsPerPage;
  const currentApplications = filteredApplications.slice(indexOfFirstApplication, indexOfLastApplication);

  const totalPages = Math.ceil(filteredApplications.length / rowsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="main-content">
          <section className="section">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
                <h1 style={{fontSize:'24px'}}>Leave Applications</h1>
              </div>
            </div>

            {/* Filter Section */}
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="form-group col-md-2">
                <label>Leave Type</label>
                <select name="type" value={type} onChange={(e) => setType(e.target.value)} className="form-control select2">
                  <option value="">Select</option>
                  <option value="Sick Leave">Sick Leave</option>
                  <option value="Casual Leave">Casual Leave</option>
                  <option value="Emergency Leave">Emergency Leave</option>
                </select>
              </div>
              <div className="form-group col-md-2">
                <label>Status</label>
                <select name="status" value={status} onChange={(e) => setStatus(e.target.value)} className="form-control select2">
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label>Applied Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </div>
                  </div>
                  <input
                    onChange={(e) => setAppliedDate(e.target.value)}
                    value={appliedDate}
                    type="date"
                    id="startDate"
                    name="startDate"
                    className="form-control"
                  />
                </div>
              </div>
              <button onClick={filterApplicationHandler} className="btn btn-primary col mr-5">
                Search
              </button>
              <button onClick={resetHandler} className="btn btn-danger col">
                Reset
              </button>
            </div>
          </section>

          {/* Table Section */}
          <div className="table-responsive">
            <table className="table table-striped table-md center-text">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Applied Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="sidebar-wrapper">
                {currentApplications.map((application, idx) => (
                  <tr
                    key={application._id}
                    className="hover-effect"
                    onClick={() => history.push(`userLeaveApplications/${application._id}`)}
                  >
                    <td>{indexOfFirstApplication + idx + 1}</td>
                    <td>{application.type}</td>
                    <td>{application.title}</td>
                    <td>{application.appliedDate}</td>
                    <td
                      className={`${
                        application.adminResponse === "Rejected"
                          ? "text-danger"
                          : application.adminResponse === "Pending"
                          ? "text-primary"
                          : "text-success"
                      }`}
                    >
                      {application.adminResponse}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="pagination justify-content-center mt-3">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="btn btn-primary mx-1"
              disabled={currentPage === 1}
            >
              <i className="fa fa-arrow-left"></i> Previous
            </button>

            {[...Array(totalPages).keys()].slice(Math.max(0, currentPage - 3), Math.min(currentPage + 2, totalPages)).map(pageNumber => (
              <button
                key={pageNumber + 1}
                onClick={() => handlePageChange(pageNumber + 1)}
                className={`btn mx-1 ${
                  currentPage === pageNumber + 1 ? "btn-primary" : "btn-secondary"
                }`}
              >
                {pageNumber + 1}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="btn btn-primary mx-1"
              disabled={currentPage === totalPages}
            >
              Next <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LeaveApplications;
