import React, { useEffect, useState } from 'react';
import { getEmployees, getLeaders, viewLeaves } from '../../http';
import { useHistory } from "react-router-dom";
import Loading from '../Loading';
import NoDataFound from '../NoDateFound';

const LeaveView = () => {
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [appliedDate, setAppliedDate] = useState('');
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [applicationsPerPage] = useState(10); // Number of applications to display per page
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [employeeMap, setEmployeeMap] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await viewLeaves({});
        const { data } = res;
        setApplications(data.data);
        setFilteredApplications(data.data); // Initially display all applications
      } catch (error) {
        console.error("Error fetching leave applications:", error);
      }
    };

    const fetchEmployees = async () => {
      try {
        let empObj = {};
        const emps = await getEmployees();
        const leaders = await getLeaders();

        emps.data.data.forEach(employee => empObj[employee.id] = [employee.name, employee.email]);
        leaders.data.data.forEach(leader => empObj[leader.id] = [leader.name, leader.email]);

        setEmployeeMap(empObj);
        setEmployees([...emps.data.data, ...leaders.data.data]);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchData();
    fetchEmployees();
  }, []);

  const searchLeaveApplications = () => {
    // Filter applications based on selected criteria
    let filtered = applications;

    if (selectedEmployee) {
      filtered = filtered.filter(application => application.applicantID === selectedEmployee);
    }

    if (type) {
      filtered = filtered.filter(application => application.type === type);
    }

    if (status) {
      filtered = filtered.filter(application => application.adminResponse === status);
    }

    if (appliedDate) {
      filtered = filtered.filter(application => application.appliedDate === appliedDate);
    }

    setFilteredApplications(filtered);
    setCurrentPage(1); // Reset pagination to first page when applying filters
  };

  // Reset Filter Handler 
  const resetLeaveFilter = () => {
    setSelectedEmployee('');
    setType('');
    setStatus('');
    setAppliedDate('');
    setFilteredApplications(applications);
  };

  // Pagination Logic
  const indexOfLastApplication = currentPage * applicationsPerPage;
  const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
  const currentApplications = filteredApplications.slice(indexOfFirstApplication, indexOfLastApplication);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredApplications.length / applicationsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {applications.length > 0 ? (
        <div className="main-content">
          <section className="section">
            <div className="card">
              <div className="card-header d-flex justify-content-between">
              <h4 style={{fontSize:"24px",color:"black"}}>Leave Applications</h4>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center w-120">
              <div className="form-group col-md-2">
                <label>Employee</label>
                <select
                  className="form-control select2"
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                >
                  <option value="">Employees</option>
                  {employees.map((employee) => (
                    <option key={employee._id} value={employee.id}>
                      {employee.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-2">
                <label>Leave Type</label>
                <select
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="form-control select2"
                >
                  <option value="">Select</option>
                  <option value="Sick Leave">Sick Leave</option>
                  <option value="Casual Leave">Casual Leave</option>
                  <option value="Emergency Leave">Emergency Leave</option>
                </select>
              </div>

              <div className="form-group col-md-2">
                <label>Status</label>
                <select
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-control select2"
                >
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>

              <div className="form-group col-md-3">
                <label>Applied Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </div>
                  </div>
                  <input
                    type="date"
                    value={appliedDate}
                    onChange={(e) => setAppliedDate(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className='col-md-3 d-flex justify-content-between'>
                <button onClick={searchLeaveApplications} className="btn btn-primary mr-2 col">
                  Search
                </button>
                <button onClick={resetLeaveFilter} className='btn btn-danger col'>Reset</button>
              </div>
            </div>
          </section>

          {/* If no data found after applying filters */}
          {filteredApplications.length === 0 ? (
            <div className="text-center mt-4">
              <p>No Data Found</p>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped table-md center-text">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Applied Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="sidebar-wrapper">
                  {currentApplications.map((application, idx) => (
                    <tr
                      className="hover-effect"
                      onClick={() => history.push(`leaves/${application._id}`)}
                      key={application._id}
                    >
                      <td>{indexOfFirstApplication + idx + 1}</td>
                      <td>{employeeMap[application.applicantID]?.[0]}</td>
                      <td>{employeeMap[application.applicantID]?.[1]}</td>
                      <td>{application.type}</td>
                      <td>{application.title}</td>
                      <td>{application.appliedDate}</td>
                      <td
                        className={`${
                          application.adminResponse === "Rejected"
                            ? "text-danger"
                            : application.adminResponse === "Pending"
                            ? "text-primary"
                            : "text-success"
                        }`}
                      >
                        {application.adminResponse}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination Controls */}
          {filteredApplications.length > 0 && (
            <nav>
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => paginate(currentPage - 1)}>
                    Previous
                  </button>
                </li>
                {pageNumbers.map(number => (
                  <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                  >
                    <button className="page-link" onClick={() => paginate(number)}>
                      {number}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => paginate(currentPage + 1)}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default LeaveView;
