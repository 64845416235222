import React from 'react';

const DeleteTeamModal = ({ show, onClose, onDelete }) => {
    return (
        <div
            className={`modal fade ${show ? 'show d-block' : ''}`}
            tabIndex="-1"
            style={{ backgroundColor: show ? 'rgba(0,0,0,0.5)' : 'transparent' }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Are you sure you want to delete this Teams?</h5>
                    </div>
                    <div className="modal-body">
                        <p>Deleting this team will remove it permanently. Do you want to proceed?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="button" className="btn btn-danger" onClick={onDelete}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteTeamModal;
