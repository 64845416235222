import swal from "sweetalert";
import { removeMember} from "../../http/index";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { setFreeEmployees, setTeamMembers } from "../../store/user-slice";
import { useSelector } from "react-redux";
import { updateEmployeeCount } from "../../store/team-slice";
import { toast } from "react-toastify";


const RowMember = ({index,data}) =>
{
    const {user} = useSelector(state => state.authSlice);
    const dispatch = useDispatch();
    const {teamMembers,freeEmployees} = useSelector(state => state.userSlice);

    const remove = async () => {
        try {
            const res = await removeMember({ userId: data.id });
            // Check if the operation was successful
            if (res.data && res.data.success) {
                toast.success(res.data.message);
    
                // Update employee count
                dispatch(updateEmployeeCount('DECREMENT'));
    
                // Update team members
                dispatch(setTeamMembers(teamMembers.filter(member => member.id !== data.id)));
    
                // Update free employees list
                if (freeEmployees) {
                    dispatch(setFreeEmployees([...freeEmployees, data]));
                } else {
                    dispatch(setFreeEmployees([data]));
                }
            } else {
                // Handle unsuccessful responses
                const errorMessage = res.data && res.data.message
                    ? res.data.message
                    : 'Failed to remove member.';
                toast.error(errorMessage);
            }
        } catch (error) {
            // Handle unexpected errors
            if (error.response && error.response.data) {
                // API returned an error response
                console.error('API Error:', error.response.data);
                toast.error(error.response.data.message || 'An error occurred while removing the member.');
            } else {
                // Unexpected errors (network issues, etc.)
                console.error('Unexpected Error:', error);
                toast.error('An unexpected error occurred while removing the member. Please try again.');
            }
        }
    };
    

    const showDialog = () =>
    {  
        swal({
        title: "Are you sure?",
        text: `You want to remove!\n${data.name} \nfrom this team`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((yes) => {
        if (yes)
            remove(); 
      });
    }

    return(
        <tr>
            <td>{index}</td>
            <td><figure className="avatar"> <img src={data.image} alt={data.name}/> </figure></td>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.mobile}</td>
            <td><div className={`badge ${data.status==='Active' ? 'badge-primary' :'badge-danger'}`}>{data.status}</div></td>
            {
                user.type==="Admin"?
                (<td><button className='btn btn-danger' onClick={showDialog}><i className="fas fa-trash-alt"></i></button></td>)
                :
                (<div></div>)
            }
            
        </tr>
    );
}

export default RowMember;