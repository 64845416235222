import React, { useEffect, useState } from 'react';
import { getAttendance, getEmployees, getLeaders } from '../../http';
import Loading from '../Loading';

const AttendanceView = () => {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [attendance, setAttendance] = useState([]);
  const [employeeMap, setEmployeeMap] = useState({});
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [resetData,setResetData] = useState([]);
  const years = [2020, 2021, 2022, 2023, 2024];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthDays = {
    January: 31, February: 28, March: 31, April: 30, May: 31, June: 30,
    July: 31, August: 31, September: 30, October: 31, November: 30, December: 31,
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const dt = new Date();
        const initialObj = {
          year: dt.getFullYear(),
          month: dt.getMonth() + 1,
          date: dt.getDate(),
        };
        const attendanceRes = await getAttendance(initialObj);
        const employeeRes = await getEmployees();
        const leaderRes = await getLeaders();

        const empMap = {};
        const combinedEmployees = [...employeeRes.data.data, ...leaderRes.data.data];
        combinedEmployees.forEach((emp) => {
          empMap[emp.id] = [emp.name, emp.email];
        });

        setEmployeeMap(empMap);
        setEmployees(combinedEmployees);
        setAttendance(attendanceRes.data.data || []);
        setResetData(attendanceRes.data.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const searchAttendance = async () => {
    const obj = {};
    if (selectedEmployee) obj['employeeID'] = selectedEmployee;
    if (selectedYear) obj['year'] = selectedYear;
    if (selectedMonth) obj['month'] = months.findIndex((month) => month === selectedMonth) + 1;
    if (selectedDay) obj['date'] = selectedDay;

    try {
      const res = await getAttendance(obj);
      setAttendance(res.data.data || []);
    } catch (error) {
      console.error('Error fetching attendance:', error);
    }
  };

  const isLeapYear = (year) => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  const numOfDays = selectedMonth === 'February' && isLeapYear(selectedYear) ? 29 : monthDays[selectedMonth];
  const days = Array.from({ length: numOfDays || 0 }, (_, index) => index + 1);

  const resetHandler = ()=>{
    setSelectedEmployee('')
    setSelectedYear('');
    setSelectedMonth('');
    setSelectedDay('');
    setAttendance(resetData);
    // setAttendance()

  };

  if (isLoading) return <Loading />;

  return (
    <div className="main-content">
      <section className="section">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4 style={{fontSize:"24px",color:"black"}}>Attendance</h4>
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <div className="col">
            <select
              className="form-control select2"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <option value="">All Employees</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-control select2"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="">Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-control select2"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Month</option>
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select
              className="form-control select2"
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              <option value="">Day</option>
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          <button onClick={searchAttendance} className="btn btn-primary col mr-5">
            Search
          </button>
          <button onClick={resetHandler} className="btn btn-danger col">
            Reset
          </button>
        </div>
      </section>
      <div className="table-responsive mt-4">
        <table className="table table-striped table-md center-text">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Date</th>
              <th>Day</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {attendance.length > 0 ? (
              attendance.map((record, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{employeeMap[record.employeeID]?.[0] || 'Unknown'}</td>
                  <td>{employeeMap[record.employeeID]?.[1] || 'Unknown'}</td>
                  <td>{`${record.date}/${record.month}/${record.year}`}</td>
                  <td>{record.day}</td>
                  <td>{record.present ? 'Present' : 'Absent'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No attendance records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttendanceView;
